import React, {useEffect, useMemo, useState} from 'react';
import { ThemeContext } from './utils/themeContext';
import { createTheme, darken, ThemeProvider } from '@mui/material';
import { website } from './utils/helpers';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Theme = (props: { children: React.ReactNode }) => {
  const [settings, setSettings] = useState<IBuildOptionsTheme | null>(null);
  // const [theme, setTheme] = useState<any>(null);

  useEffect(() => {
    switch (website.code) {
      case 'HU':
      case 'AM':
      case 'NG':
      case 'CF':
      case 'PA':
      case 'RO':
      case 'FA':
      case 'IP':
      case 'MS':
      case 'JO':
      case 'BK':
      case 'CE':
      case 'PATEST':
        setSettings({
          primary: '#97DDCC',
          secondary: '#FFEE00',
          tertiary: '#2357D0',
          black: '#000',
          contrastText: '#000',
          iconColor: '#FFF',
          paperColor: '#EEE',
          generalUnderlineColor: '#FFEE00',
          mainFont: 'Circular Spotify Tx T Book',
          mainFontLight: 'Circular Spotify Tx T Light',
          mainFontBold: 'Circular Spotify Tx T Bold',
          secondaryFont: 'Circular Spotify Tx T Book',
          secondaryFontBold: 'Circular Spotify Tx T Bold',
          inputRadius: 0,
          paperRadius: 0,
          iconBorderRadius: 0,
          formBorderRadius: 0
        });
        break;
      case 'VP':
        setSettings({
          primary: '#B3AC54',
          secondary: '#325B45',
          tertiary: '#9ABDC7',
          black: '#000',
          contrastText: '#FFF',
          iconColor: '#132646',
          paperColor: '#FEF9EB',
          generalUnderlineColor: '#9ABDC7',
          mainFont: 'SpaceGrotesk',
          mainFontBold: 'SpaceGotesk Bold',
          secondaryFont: 'Garamond Regular',
          secondaryFontBold: 'GaramondBold',
          inputRadius: 5,
          paperRadius: 8,
          iconBorderRadius: 0,
          formBorderRadius: 0
        });
        break;
      /*case 'CE':
        setSettings({
          primary: '#619B8A',
          secondary: '#FCCA47',
          tertiary: '#A458A4',
          black: '#000',
          contrastText: '#000',
          iconColor: '#fff',
          paperColor: '#EEE',
          generalUnderlineColor: '#FCCA47',
          mainFont: 'SpaceGrotesk',
          mainFontBold: 'SpaceGotesk Bold',
          secondaryFont: 'Garamond Regular',
          secondaryFontBold: 'GaramondBold',
          inputRadius: 5,
          paperRadius: 8,
          iconBorderRadius: 0,
          formBorderRadius: 0
        });
        break;*/
      default:
        console.log('website not found', website)
        setSettings({
          primary: '#97DDCC',
          secondary: '#FFEE00',
          tertiary: '#FFBE45',
          black: '#000',
          contrastText: '#000',
          iconColor: '#fff',
          paperColor: '#EEE',
          generalUnderlineColor: '#FFEE00',
          mainFont: 'Circular Spotify Tx T Book',
          mainFontLight: 'Circular Spotify Tx T Light',
          mainFontBold: 'Circular Spotify Tx T Bold',
          secondaryFont: 'Circular Spotify Tx T Book',
          secondaryFontBold: 'Circular Spotify Tx T Bold',
          inputRadius: 0,
          paperRadius: 0,
          iconBorderRadius: 0,
          formBorderRadius: 0
        });
        break;
    }
  }, []);

  const theme = useMemo( () => {
    return settings ? buildOptions(settings) : null;
  }, [settings, website.code])

  return theme ? (
    <ThemeContext.Provider value={theme}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  ) : <></>
};

export default Theme;

interface IBuildOptionsTheme {
  primary: string;
  secondary: string;
  tertiary: string;
  black: string;
  mainFont: string;
  mainFontLight?: string;
  mainFontBold?: string;
  inputRadius: string | number;
  paperRadius: string | number;
  secondaryFont: string;
  secondaryFontBold: string;
  contrastText?: string;
  iconColor: string;
  paperColor: string;
  iconBorderRadius?: string | number;
  formBorderRadius?: string | number;
  generalUnderlineColor: string;
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    tertiary: Palette['primary'];
    black: Palette['primary'];
    paperColor: Palette['primary'];
    generalUnderlineColor:  Palette['primary'];
  }

  interface PaletteOptions {
    tertiary: PaletteOptions['primary'];
    black: PaletteOptions['primary'];
    paperColor: Palette['primary'];
    generalUnderlineColor:  Palette['primary'];
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    radius: {
      paperRadius: string | number;
      iconBorderRadius: string | number;
      formBorderRadius: string | number;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    radius?: {
      paperRadius?: string | number;
      iconBorderRadius?: string | number;
      formBorderRadius?: string | number;
    };
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
  interface ButtonPropsVariantOverrides {
    containedSecondaryVP: true;
  }
}

const buildOptions = ({
  primary,
  secondary,
  tertiary,
  black,
  mainFont,
  mainFontLight,
  mainFontBold,
  secondaryFont,
  secondaryFontBold,
  inputRadius,
  paperRadius,
  contrastText,
  iconColor,
  paperColor,
  iconBorderRadius,
  formBorderRadius,
  generalUnderlineColor
}: IBuildOptionsTheme) => {
  return createTheme({
    radius: {
      paperRadius: paperRadius,
      iconBorderRadius: iconBorderRadius,
      formBorderRadius: formBorderRadius
    },
    palette: {
      primary: {
        main: primary,
        contrastText: contrastText,
      },
      secondary: {
        main: secondary,
        contrastText: contrastText,
      },
      tertiary: {
        main: tertiary,
        contrastText: iconColor,
      },
      generalUnderlineColor: {
        main: generalUnderlineColor,
        light: '',
        dark: '',
        contrastText: ''
      },
      paperColor: {
        main: paperColor,
        light: '',
        dark: '',
        contrastText: ''
      },
      black: {
        main: black,
        contrastText: iconColor,
      },
      common: {
        black: '#000',
        white: '#FFF'
      }
    },
    typography: {
      htmlFontSize: 16,
      h1: {
        fontSize: 50,
        fontFamily: secondaryFontBold,
        lineHeight: '60px'
      },
      h2: {
        fontSize: 46,
        fontFamily: secondaryFontBold,
        lineHeight: '36px'
      },
      h3: {
        fontSize: 36,
        fontFamily: secondaryFontBold + '!important',
        lineHeight: '46px'
      },
      h4: {
        fontSize: 22,
        fontFamily: mainFontBold,
        lineHeight: '32px'
      },
      h5: {
        fontSize: 18,
        fontFamily: mainFontBold,
        lineHeight: '28px'
      },
      h6: {
        fontSize: 16,
        fontFamily: mainFont,
        fontWeight: 500,
        lineHeight: '26px'
      },
      subtitle1: {
        fontSize: 14,
        fontFamily: mainFontBold,
        lineHeight: '26px'
      },
      subtitle2: {
        fontSize: 12,
        fontFamily: mainFontLight,
        lineHeight: '22px'
      },
      body1: {
        fontSize: 16,
        fontFamily: mainFont,
        lineHeight: '26px',
        fontWeight: 400
      },
      body2: {
        fontSize: 14,
        fontFamily: mainFontLight,
        lineHeight: '24px',
        fontWeight: 300
      },
      caption: {
        fontSize: 18,
        fontFamily: mainFont,
        fontWeight: 500
      },
      overline: {
        fontFamily: mainFont,
        fontSize: 14
      }
    },
    // Globals
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '@global': {
            body: {
              margin: 0
            },
            ul: {
              listStyle: 'none',
              padding: '0'
            },
            a: {
              textDecoration: 'none',
              color: 'inherit'
            },
            img: {
              maxWidth: '100%',
              display: 'block'
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:hover': {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent'
              }
            },
            borderRadius: inputRadius,
            '&:hover $notchedOutline': {
              borderColor: primary
            },
            '&$focused $notchedOutline': {
              // border: '0px solid blue'
            },
            '&.Mui-error': {
              border: '2px solid #b32e24',
              'input::placeholder': {
                color: 'blue!important'
              }
            }
          },
          notchedOutline: {
            borderColor: 'transparent'
          }
        }
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: '#FFFFFF',
            border: '1px solid #E5E5E5',
            borderRadius: formBorderRadius,
            '&::before': {
              borderBottom: '0!important'
            },
            '&::after': {
              borderBottom: '0!important'
            },
            '&.Mui-error': {
              border: '2px solid #b32e24',
              'input::placeholder': {
                color: '#b32e24!important'
              }
            }
          },
          input: {
            paddingBottom: 'initial',
            paddingTop: 'initial'
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: 9,
            margin: 0,
            marginTop: 3,
            marginLeft: '5px!important',
            marginRight: '0!important'
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: 8
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            height: 48,
            background: 'transparent',
            borderRadius: 5,
            borderColor: 'transparent',
            color: '#333333'
          }
        }
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            borderRadius: 5,
            width: '100%',
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
              borderRadius: 5
              /*
              width: '100%',
*/
            }
          }
        }
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            fontFamily: mainFont + '!important',
            '&.MuiPickersDay-root': {
              fontSize: 12,
              '&.Mui-disabled:hover': {
                border: 'none!important'
              }
            }
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: 48,
            border: '1px solid #e5e5e5',
            fieldset: {
              '&.MuiOutlinedInput-notchedOutline': {
                display: 'none'
              }
            }
          }
        }
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            fontSize: 24,
            color: '#000'
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            fontSize: 24,
            color: '#000'
          },
          iconOutlined: {
            // right: 25
          },
          iconOpen: {
            transform: 'rotate(0deg)'
          },
          select: {
            fontSize: 14
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            fontSize: 24,
            color: 'inherit'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          text: {
            padding: 0,
            textTransform: 'initial',
            fontFamily: mainFont,
            '&[type=reset]': {
              color: 'red'
            }
          },
          textInherit: {
            '&:hover': {
              backgroundColor: 'transparent!important'
            }
          },
          outlined: {
            height: 48,
            borderRadius: 5
          },
          contained: {
            height: 48,
            boxShadow: '0px 3px 6px #00000029!important',
            borderRadius: inputRadius,
            fontFamily: mainFont
          },
          containedPrimary: {
            '& $label': {
              color: '#FFFFFF',
              fontWeight: 'bold',
              textTransform: 'uppercase'
            }
          },
          containedSecondary: {
            '& $label': {
              color: contrastText,
              fontWeight: 'bold',
              textTransform: 'uppercase',
              fontFamily: mainFontBold + '!important'
            },
            '&:hover': {
              backgroundColor: darken(secondary, 0.2)
            }
          }
        },
        variants: [
          {
            props: { variant: 'containedSecondaryVP' },
            style: {
              borderRadius: paperRadius + 'px!important',
              backgroundColor: 'white!important',
              border: '2px solid',
              borderColor: secondary + '!important',
              color: secondary + '!important',
              fontSize: 16,
              textTransform: 'uppercase',
              fontFamily: mainFont,
              '&:hover': {
                backgroundColor: secondary + '!important',
                color: 'white!important',
                borderColor: secondary + '!important',
                border: '2px solid'
              },
              '&.Mui-disabled': {
                backgroundColor: '#AFAFAF',
                borderColor: '#AFAFAF',
                color: '#4B4B4B',
              }
            }
          }
        ]
      },
      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: paperRadius
          },
          root: {
            /*'& h4': {
              fontFamily: mainFontBold
            },
            '& h6': {
              fontFamily: mainFontBold
            },*/
            '.MuiPickersDay-today': {
              border: '1px solid',
              borderColor: primary + '!important'
            },
            '.MuiDayCalendar-weekDayLabel': {
              fontSize: 12
            },
            '.MuiDateRangePickerDay-day:hover': {
              border: 'none!important',
              backgroundColor: primary + '4d!important'
            },
            '.MuiDateRangePickerDay-day.Mui-disabled': {
              fontFamily: mainFontLight
            },
            '.MuiDateRangePickerDay-day': {
              fontFamily: mainFontBold
            },
            '.MuiDateRangeCalendar-monthContainer': {
              borderRight: 'none!important'
            },
            '.MuiFormControl-root.MuiTextField-root .Mui-disabled': {
              borderRadius: formBorderRadius
            }
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            width: '100%!important',
            '.Mui-disabled': {
              borderColor: 'transparent',
              fieldset: {
                borderColor: 'transparent!important'
              }
            }
          }
        }
      },
      MuiPopover: {
        styleOverrides: {
          root: {
            marginTop: 5,
            '& .MuiPaper-root': {
              // overflow: 'scroll!important',
              border: '1px solid black',
              /*'& .MuiBox-root': {
                minWidth: 192
              },*/
              '& .MuiBadge-root': {
                content: '""',
                display: 'block',
                position: 'absolute',
                borderStyle: 'solid',
                borderColor: '#000',
                borderWidth: '1px 1px 0 0',
                width: 15,
                height: 15,
                backgroundColor: 'white!important',
                top: -9,
                left: 17,
                background: 'inherit',
                WebkitTransform: 'rotate(-45deg)',
                MozTransform: 'rotate(-45deg)',
                OTransform: 'rotate(-45deg)',
                transform: 'rotate(-45deg)',
                zIndex: 10
              }
            }
          },
          paper: {
            fontFamily: mainFont+'!important'
          }
        }
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: paperRadius,
            boxShadow: '0px 3px 6px #00000029!important'
          }
        }
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            alignItems: 'baseline',
            '& i': {
              color: primary,
              marginRight: 9
            }
          },
          dense: {
            '& span': {
              fontSize: 14
            },
            '& i': {
              color: 'initial'
            }
          },
          gutters: {
            paddingRight: 0,
            paddingLeft: 0
          }
        }
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            width: 'fit-content',
            margin: 'auto'
          }
        }
      },
      MuiStep: {
        styleOverrides: {
          root: {
            maxWidth: 'fit-content',
            paddingLeft: '0!important',
            paddingRight: '75px!important'
          }
        }
      },
      MuiStepConnector: {
        styleOverrides: {
          horizontal: {
            top: 24,
            left: 'calc(-50% + -45px)',
            right: 'calc(50% + 60px)',
            zIndex: '-1',
            '&.Mui-completed': {
              span: {
                borderColor: secondary,
                borderStyle: 'solid!important'
              }
            },
            span: {
              borderTopStyle: 'dashed!important'
            }
          }
        }
      },
      MuiStepIcon: {
        styleOverrides: {
          text: {
            fontSize: 8,
            fontFamily: mainFontBold,
            color: primary,
            fill: 'rgba(0, 0, 0, 0.87)'
          },
          root: {
            color: '#DEDEDE',
            borderColor: '#DEDEDE',
            backgroundColor: '#FFF',
            border: 1,
            borderStyle: 'solid',
            width: 46,
            height: 46,
            zIndex: 2,
            circle: {
              display: 'none'
            },
            '&.Mui-active': {
              color: '#FFEE00!important',
              borderColor: secondary,
              border: 1,
              borderStyle: 'solid',
              circle: {
                display: 'none'
              }
            },
            '&.Mui-completed': {
              color: '#000',
              backgroundColor: '#FFEE00!important',
              border: 0
            }
          }
        }
      },
      MuiStepLabel: {
        styleOverrides: {
          label: {
            color: '#DEDEDE',
            '&.Mui-active': {
              fontFamily: mainFontBold
            },
            '&.Mui-completed': {
              fontFamily: mainFontBold
            }
          },
          root: {
            color: primary,
            '&.Mui-active': {
              color: '#000000',
              fontFamily: mainFontBold
            },
            '&.Mui-completed': {
              color: '#535353',
              fontWeight: mainFontBold
            }
          },
          iconContainer: {
            '&.Mui-completed': {
              width: 46,
              height: 46,
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid' + secondary,
              backgroundColor: secondary,
              color: contrastText + '!important'
            }
          }
        }
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            color: 'black'
          },
          rail: {
            color: 'white',
            opacity: 1
          },
          thumb: {
            borderRadius: 0
          },
          markLabel: {
            '&[data-index="0"]': {
              left: '5%!important'
            },
            '&[data-index="1"]': {
              left: '95%!important'
            },
            top: '-35px',
            backgroundColor: '#FFF',
            textAlign: 'center',
            padding: 3,
            border: '1px solid black'
          },
          mark: {
            color: '#FFF'
          },
          markActive: {
            color: '#000'
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: 5
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            boxShadow: '0px 3px 6px #00000029',
            fontSize: 12,
            fontWeight: 'bold',
            borderRadius: iconBorderRadius + '!important',
            fontFamily: mainFontBold
          },
          colorSecondary: {
            //color: 'white'
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          popper: {
            zIndex: 999999,
          },
          tooltip: {
            fontFamily: mainFont + '!important'
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            '& a': {
              color: secondary
            }
          },
          label: {
            width: '100%'
          }
        }
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            'button': {
              color: '#000',
              fontFamily: mainFontBold
            }
          }
        }
      },
      MuiCircularProgress: {
        styleOverrides: {
          svg: {
            color: '#FFEE00!important'
          }
        }
      }
    }
  });
};
